import { useState, useCallback, useEffect } from "react";
import { useFetchData } from "../../hooks/useFetchData";
import {
  RECEPTIONIST_API_ENDPOINTS,
  THERAPIST_API_ENDPOINTS,
  SERVICE_API_ENDPOINTS,
  LOCATION_API_ENDPOINTS,
  SALESPERSON_API_ENDPOINTS,
  CLIENT_API_ENDPOINTS,
} from "../../services/apiConstants";

const useFetchUserData = (isAuthenticated) => {
  const [fetchFlags, setFetchFlags] = useState({
    salespersons: false,
    therapists: false,
    locations: false,
    clientData: false,
    clients: false,
    treatments: false,
    treatmentCategories: false,
    memberships: false,
    membershipCategories: false,
    membershipAllowedTreatments: false,
    // Initialize additional flags as needed
  });

  const [clientId, setClientId] = useState(null);

  const [clientsPage, setClientsPage] = useState(1);
  const [clientsPageSize, setClientsPageSize] = useState(100);

  const setFetchFlag = useCallback((entity, value) => {
    setFetchFlags((prev) => ({ ...prev, [entity]: value }));
  }, []);

  const dataEntities = {
    salespersons: useFetchData(
      SALESPERSON_API_ENDPOINTS.SALESPERSONS,
      fetchFlags.salespersons
    ),
    therapists: useFetchData(
      THERAPIST_API_ENDPOINTS.THERAPISTS,
      fetchFlags.therapists
    ),
    receptionists: useFetchData(
      RECEPTIONIST_API_ENDPOINTS.RECEPTIONISTS,
      fetchFlags.receptionists
    ),
    locations: useFetchData(
      LOCATION_API_ENDPOINTS.LOCATIONS,
      fetchFlags.locations
    ),
    clientData: useFetchData(
      CLIENT_API_ENDPOINTS.CLIENT(clientId),
      fetchFlags.clientData
    ),
    clients: useFetchData(CLIENT_API_ENDPOINTS.CLIENTS, fetchFlags.clients, {
      page: clientsPage,
      pageSize: clientsPageSize,
    }),
    treatments: useFetchData(
      SERVICE_API_ENDPOINTS.TREATMENTS,
      fetchFlags.treatments
    ),
    treatmentCategories: useFetchData(
      SERVICE_API_ENDPOINTS.TREATMENT_CATEGORIES,
      fetchFlags.treatmentCategories
    ),
    memberships: useFetchData(
      SERVICE_API_ENDPOINTS.MEMBERSHIPS,
      fetchFlags.memberships
    ),
    membershipCategories: useFetchData(
      SERVICE_API_ENDPOINTS.MEMBERSHIP_CATEGORIES,
      fetchFlags.membershipCategories
    ),
    membershipAllowedTreatments: useFetchData(
      SERVICE_API_ENDPOINTS.MEMBERSHIP_ALLOWED_TREATMENTS,
      fetchFlags.membershipAllowedTreatments
    ),
    // Add other entities similarly
  };

  // Dynamically generate fetchedData, loading, and errors objects
  const fetchedData = Object.keys(dataEntities).reduce((acc, key) => {
    acc[key] = dataEntities[key].data;
    return acc;
  }, {});

  const loading = Object.keys(dataEntities).reduce((acc, key) => {
    acc[`loading${key.charAt(0).toUpperCase() + key.slice(1)}`] =
      dataEntities[key].isLoading;
    return acc;
  }, {});

  const errors = Object.keys(dataEntities).reduce((acc, key) => {
    acc[`${key}Error`] = dataEntities[key].isError;
    return acc;
  }, {});

  // Triggers for fetching data
  const triggerFetches = Object.keys(fetchFlags).reduce((acc, entity) => {
    acc[`triggerFetch${entity.charAt(0).toUpperCase() + entity.slice(1)}`] =
      () => setFetchFlag(entity, true);
    return acc;
  }, {});

  // Dynamically create mutators for each entity
  const mutators = Object.keys(dataEntities).reduce((acc, key) => {
    acc[`mutate${key.charAt(0).toUpperCase() + key.slice(1)}`] =
      dataEntities[key].mutate;
    return acc;
  }, {});

  const setFetchClientData = useCallback(() => {
    if (clientId) setFetchFlag("clientData", true);
  }, [clientId, setFetchFlag]);

  if (!isAuthenticated) {
    return {
      fetchedData: {},
      loading: {},
      errors: {},
      mutators: {},
    };
  }

  return {
    fetchedData,
    loading,
    errors,
    ...triggerFetches,
    mutators,
    setClientId,
    setFetchClientData,
    setClientsPage,
    setClientsPageSize,
    clientsPage,
    clientsPageSize,
    // Include additional setters and utilities as needed
  };
};

export default useFetchUserData;
