//menuItems
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";

import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";

import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";

import CardMembershipIcon from "@mui/icons-material/CardMembership";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";

import SummarizeIcon from "@mui/icons-material/Summarize";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";

import InsertChartIcon from "@mui/icons-material/InsertChart";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";

// import NotificationsIcon from "@mui/icons-material/Notifications";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";

import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { isFeatureEnabled } from "../../utils/featureFlags";
import NotificationsMenuItem from "./NotificationsMenuItem";

// import EventNoteIcon from "@mui/icons-material/EventNote";
//import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
// import Inventory2Icon from "@mui/icons-material/Inventory2";
// import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

export const adminItems = [
  ...(isFeatureEnabled("admin_dashboard")
    ? [
        {
          icon: <InsertChartIcon />,
          OutlineIcon: <InsertChartOutlinedIcon />,
          title: "Admin Dashboard",
          path: "/admin/dashboard",
        },
      ]
    : []),

  ...(isFeatureEnabled("users")
    ? [
        {
          icon: <ManageAccountsIcon />,
          OutlineIcon: <ManageAccountsOutlinedIcon />,
          title: "Users",
          path: "/admin/users",
        },
      ]
    : []),
  {
    icon: <AssignmentIndIcon />,
    OutlineIcon: <AssignmentIndOutlinedIcon />,
    title: "Employees",
    path: "/admin/employees",
  },
  {
    icon: <PeopleIcon />,
    OutlineIcon: <PeopleOutlinedIcon />,
    title: "Clients",
    path: "/admin/clients",
  },
  {
    icon: <StoreMallDirectoryIcon />,
    OutlineIcon: <StoreMallDirectoryOutlinedIcon />,
    title: "Locations",
    path: "/admin/locations",
  },
  {
    icon: <CardMembershipIcon />,
    OutlineIcon: <CardMembershipOutlinedIcon />,
    title: "Services",
    path: "/admin/services",
  },
  ...(isFeatureEnabled("banks")
    ? [
        {
          icon: <AccountBalanceIcon />,
          OutlineIcon: <AccountBalanceOutlinedIcon />,
          title: "Banks",
          path: "/admin/banks",
        },
      ]
    : []),
  ...(isFeatureEnabled("reports")
    ? [
        {
          icon: <SummarizeIcon />,
          OutlineIcon: <SummarizeOutlinedIcon />,
          title: "Reports",
          path: "/admin/reports",
        },
      ]
    : []),
  {
    icon: <SettingsIcon />,
    OutlineIcon: <SettingsOutlinedIcon />,
    title: "Settings",
    path: "/admin/settings",
  },

  // {
  //   icon: <HomeRepairServiceIcon />,
  //   title: "Products",
  //   path: "/admin/products",
  // },
  // {
  //   icon: <Inventory2Icon />,
  //   title: "Inventory",
  //   path: "/admin/inventory",
  // },
  // {
  //   icon: <LocalShippingIcon />,
  //   title: "Deliveries",
  //   path: "/admin/deliveries",
  // },
  // {
  //   icon: <PointOfSaleIcon />,
  //   title: "Sales",
  //   path: "/admin/sales",
  // },
];

export const userItems = [
  {
    value: "/dashboard",
    label: "Dashboard",
    Icon: <DashboardIcon />,
    OutlineIcon: <DashboardOutlinedIcon />,
    isAdmin: false,
  },
  {
    value: "/notifications",
    label: "Notifications",
    Icon: <NotificationsMenuItem />,
    OutlineIcon: <NotificationsMenuItem />,
    isAdmin: false,
  },
  // {
  //   icon: <EventNoteIcon />,
  //   title: "Schedule",
  //   path: "/schedule",
  // },
];

export const mobileItems = [
  {
    value: "/dashboard",
    label: "Dashboard",
    Icon: <DashboardIcon />,
    OutlineIcon: <DashboardOutlinedIcon />,
    isAdmin: false,
  },
  {
    value: "/admin/menu",
    label: "Admin",
    Icon: <SettingsIcon />,
    OutlineIcon: <SettingsOutlinedIcon />,
    isAdmin: true,
  },
  {
    value: "/notifications",
    label: "Notifications",
    Icon: <NotificationsMenuItem />,
    OutlineIcon: <NotificationsMenuItem />,
    isAdmin: false,
  },
  // {
  //   icon: <EventNoteIcon />,
  //   title: "Schedule",
  //   path: "/schedule",
  // },
];
