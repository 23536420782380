import React, { Suspense, lazy } from "react";
import Layout from "../components/Layout";
import { Routes, Route, Navigate } from "react-router-dom";
import { ProtectedRoute } from "../components/protectedroute/ProtectedRoute";
import LoadingBackdropCirc from "../components/loading/LoadingBackdropCirc";
const Dashboard = lazy(() => import("../pages/user/Dashboard"));
const Profile = lazy(() => import("../pages/user/Profile"));
const ClientPage = lazy(() => import("../pages/user/ClientPage"));
const AdminRoutes = lazy(() => import("../components/admin/AdminRoutes"));
const Notifications = lazy(() =>
  import("../pages/notifications/Notifications")
);

export default function PrivateRoutes() {
  return (
    <Suspense fallback={<LoadingBackdropCirc open={true} />}>
      <Layout>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/:clientId"
            element={
              <ProtectedRoute>
                <ClientPage isAdmin={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute adminOnly={true}>
                <AdminRoutes />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </Layout>
    </Suspense>
  );
}
