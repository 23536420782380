//useFetchadminData
import { useState, useCallback } from "react";
import { useFetchData } from "../useFetchData";
import {
  BANK_API_ENDPOINTS,
  CLIENT_API_ENDPOINTS,
  LOCATION_API_ENDPOINTS,
  RECEPTIONIST_API_ENDPOINTS,
  REPORT_API_ENDPOINTS,
  SALESPERSON_API_ENDPOINTS,
  SERVICE_API_ENDPOINTS,
  THERAPIST_API_ENDPOINTS,
  USER_API_ENDPOINTS,
} from "../../services/apiConstants";
import dayjs from "dayjs";

const useFetchAdminData = (isAdmin, reportDate = null) => {
  const [fetchFlags, setFetchFlags] = useState({
    users: false,
    roles: false,
    salespersons: false,
    therapists: false,
    receptionists: false,
    locations: false,
    banks: false,
    clients: false,
    clientData: false,
    clientsArrivalsCount: false,
    clientsArrivalsData: false,
    treatments: false,
    treatmentCategories: false,
    memberships: false,
    membershipCategories: false,
    membershipAllowedTreatments: false,
    dailySalesReport: false,
    personalSales: false,
    locationsSales: false,
    salesLeaderboard: false,

    // Add other entities as needed
  });

  const setFetchFlag = useCallback((entity, value) => {
    setFetchFlags((prev) => {
      if (prev[entity] === value) {
        return prev;
      }
      return { ...prev, [entity]: value };
    });
  }, []);

  const [clientId, setClientId] = useState(null);

  const [clientsPage, setClientsPage] = useState(1);
  const [clientsPageSize, setClientsPageSize] = useState(100);

  const [dailyReportDate, setDailyReportDate] = useState(reportDate);

  // personal sales report
  const [personalSalesReportDate, setPersonalSalesReportDate] = useState(
    new Date()
  );
  const [selectedSalesperson, setSelectedSalesperson] = useState(null);

  //locations sales report
  const [locationsSalesReportDate, setLocationsSalesReportDate] = useState(
    new Date()
  );

  const [leaderboardParams, setLeaderboardParams] = useState({
    //type: "salesperson",
    month: dayjs().month() + 1, // Default to current month
    year: dayjs().year(), // Default to current year
    cutoff: 1, // Default cutoff
  });

  const dataEntities = {
    users: useFetchData(USER_API_ENDPOINTS.USERS, fetchFlags.users),
    roles: useFetchData(USER_API_ENDPOINTS.ROLES, fetchFlags.roles),
    salespersons: useFetchData(
      SALESPERSON_API_ENDPOINTS.SALESPERSONS,
      fetchFlags.salespersons
    ),
    therapists: useFetchData(
      THERAPIST_API_ENDPOINTS.THERAPISTS,
      fetchFlags.therapists
    ),
    receptionists: useFetchData(
      RECEPTIONIST_API_ENDPOINTS.RECEPTIONISTS,
      fetchFlags.receptionists
    ),
    locations: useFetchData(
      LOCATION_API_ENDPOINTS.LOCATIONS,
      fetchFlags.locations
    ),
    banks: useFetchData(BANK_API_ENDPOINTS.BANKS, fetchFlags.banks),
    clientData: useFetchData(
      CLIENT_API_ENDPOINTS.CLIENT(clientId),
      fetchFlags.clientData
    ),
    clients: useFetchData(CLIENT_API_ENDPOINTS.CLIENTS, fetchFlags.clients, {
      page: clientsPage,
      pageSize: clientsPageSize,
    }),
    clientsArrivalsCount: useFetchData(
      "clientsArrivalsCount",
      fetchFlags.clientsArrivalsCount
    ),
    clientsArrivalsData: useFetchData(
      "clientsArrivalsData",
      fetchFlags.clientsArrivalsData
    ),
    treatments: useFetchData(
      SERVICE_API_ENDPOINTS.TREATMENTS,
      fetchFlags.treatments
    ),
    treatmentCategories: useFetchData(
      SERVICE_API_ENDPOINTS.TREATMENT_CATEGORIES,
      fetchFlags.treatmentCategories
    ),
    memberships: useFetchData(
      SERVICE_API_ENDPOINTS.MEMBERSHIPS,
      fetchFlags.memberships
    ),
    // membershipCategories: useFetchData(
    //   SERVICE_API_ENDPOINTS.MEMBERSHIP_CATEGORIES,
    //   fetchFlags.membershipCategories
    // ),
    // membershipAllowedTreatments: useFetchData(
    //   "membershipAllowedTreatments",
    //   fetchFlags.membershipAllowedTreatments
    // ),
    dailySalesReport: useFetchData(
      REPORT_API_ENDPOINTS.GET_SALES_DATA,
      fetchFlags.dailySalesReport,
      dailyReportDate ? { date: dailyReportDate } : {}
    ),

    //personal sales report
    personalSales: useFetchData(
      REPORT_API_ENDPOINTS.GET_PERSONAL_SALES,
      fetchFlags.personalSales,
      {
        salesperson_id: selectedSalesperson,
        month: dayjs(personalSalesReportDate).month() + 1,
        year: dayjs(personalSalesReportDate).year(),
      }
    ),

    //location sales report
    locationsSales: useFetchData(
      REPORT_API_ENDPOINTS.GET_LOCATIONS_SALES,
      fetchFlags.locationsSales,
      {
        month: dayjs(locationsSalesReportDate).month() + 1,
        year: dayjs(locationsSalesReportDate).year(),
      }
    ),
    salesLeaderboard: useFetchData(
      REPORT_API_ENDPOINTS.LEADERBOARDS,
      fetchFlags.salesLeaderboard,
      leaderboardParams
    ),
    // Continue for other data entities
  };

  // Dynamically generate fetchedData, loading, and errors objects
  const fetchedData = Object.keys(dataEntities).reduce((acc, key) => {
    acc[key] = dataEntities[key].data;
    return acc;
  }, {});

  const loading = Object.keys(dataEntities).reduce((acc, key) => {
    acc[`loading${key.charAt(0).toUpperCase() + key.slice(1)}`] =
      dataEntities[key].isLoading;
    return acc;
  }, {});

  const errors = Object.keys(dataEntities).reduce((acc, key) => {
    acc[`${key}Error`] = dataEntities[key].isError;
    return acc;
  }, {});

  // Triggers for fetching data
  const triggerFetches = Object.keys(fetchFlags).reduce((acc, entity) => {
    acc[`triggerFetch${entity.charAt(0).toUpperCase() + entity.slice(1)}`] =
      () => setFetchFlag(entity, true);
    return acc;
  }, {});

  // Dynamically create mutators for each entity
  const mutators = Object.keys(dataEntities).reduce((acc, key) => {
    acc[`mutate${key.charAt(0).toUpperCase() + key.slice(1)}`] =
      dataEntities[key].mutate;
    return acc;
  }, {});

  const setFetchClientData = useCallback(() => {
    if (clientId) setFetchFlag("clientData", true);
  }, [clientId, setFetchFlag]);

  const triggerFetchSalesLeaderboard = useCallback(
    (params) => {
      setLeaderboardParams(params);
      setFetchFlag("salesLeaderboard", true);
    },
    [setFetchFlag]
  );

  if (!isAdmin) {
    return {
      fetchedData: {},
      loading: {},
      errors: {},
      mutators: {},
    };
  }

  return {
    fetchedData,
    loading,
    errors,
    ...triggerFetches,
    mutators,
    setClientId,
    setFetchClientData,
    setClientsPage,
    setClientsPageSize,
    clientsPage,
    clientsPageSize,
    setDailyReportDate,
    setSelectedSalesperson,
    selectedSalesperson,
    setPersonalSalesReportDate,
    setLocationsSalesReportDate,
    triggerFetchSalesLeaderboard,
    // Include mutators and other utilities as needed
  };
};

export default useFetchAdminData;
