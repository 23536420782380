import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const useAuthNavigation = (userDetails, isLoading) => {
  const navigate = useNavigate();
  const location = useLocation();
  const notLoginPage = !location.pathname.includes("/login");

  useEffect(() => {
    if (isLoading) return; // Avoid navigation while loading

    if (notLoginPage && !userDetails) {
      navigate("/login");
    } else if (userDetails && location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [userDetails, notLoginPage, navigate, isLoading, location.pathname]);
};
