export const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://127.0.0.1:8000/";

export const ADMIN_SETTINGS = {
  UPLOAD_LOGO: "/configurations/update-logo/",
};

export const CONFIG = "/configurations/";

export const USER_API_ENDPOINTS = {
  TOKEN: "/users/token/",
  REFRESH_TOKEN: "/users/token/refresh/",
  MY_DATA: "/users/my_data/",
  MY_GROUPS: "/users/my_groups/",
  LOGIN: "/users/login/",
  LOGOUT: "/users/logout/",
  USERS: "/users/",
  USER: (userId) => `/users/${userId}/`,
  UPDATE_USER_PASSWORD: (userId) => `/users/${userId}/update_password/`,
  ROLES: "/users/groups/",
};

export const LOCATION_API_ENDPOINTS = {
  LOCATIONS: "/locations/",
  LOCATION: (locId) => `/locations/${locId}/`,
};

export const SALESPERSON_API_ENDPOINTS = {
  SALESPERSONS: "/salespersons/",
  SALESPERSON: (spId) => `/salespersons/${spId}/`,
  SALESPERSON_BY_USER_ID: (userId) => `/salespersons/by-user/${userId}/`,
};

export const THERAPIST_API_ENDPOINTS = {
  THERAPISTS: "/therapists/",
  THERAPIST: (tpId) => `/therapists/${tpId}/`,
  THERAPIST_BY_USER_ID: (userId) => `/therapists/by-user/${userId}/`,
};

export const RECEPTIONIST_API_ENDPOINTS = {
  RECEPTIONISTS: "/receptionists/",
  RECEPTIONIST: (rcpId) => `/receptionists/${rcpId}/`,
  RECEPTIONIST_BY_USER_ID: (userId) => `/receptionists/by-user/${userId}/`,
};

export const BANK_API_ENDPOINTS = {
  BANKS: "/banks/",
  BANK: (bankId) => `/banks/${bankId}/`,
};

export const CLIENT_API_ENDPOINTS = {
  CLIENTS: "/clients/",
  CLIENT_SEARCH: "/clients/search/",
  CLIENT: (clientId) => `/clients/${clientId}/`,

  CLIENT_MEMBERSHIPS: `/clients/memberships/`,
  CLIENT_MEMBERSHIP: (membershipId) => `/clients/memberships/${membershipId}/`,

  CLIENT_VOUCHERS: `/clients/treatments/`,
  CLIENT_VOUCHER: (voucherId) => `/clients/treatments/${voucherId}/`,

  CLIENT_ARRIVALS: `/clients/arrivals/`,
  CLIENT_ARRIVAL: (arrivalId) => `/clients/arrivals/${arrivalId}/`,
  ARRIVALS_COUNT: "/clients/arrivals/arrivals_count/",
  ARRIVALS_DATA: "/clients/arrivals/data/",

  CLIENT_SERVICES: (clientId) => `/clients/${clientId}/services/`,
};

export const PRODUCT_API_ENDPOINTS = {
  PRODUCTS: "/products/",
  PRODUCT: (productId) => `/products/${productId}/`,
};

export const SALE_API_ENDPOINTS = {
  SALES: "/sales/",
  SALE: (saleId) => `/sales/${saleId}/`,
};

export const SERVICE_API_ENDPOINTS = {
  TREATMENTS: "/services/treatments/",
  TREATMENT: (treatmentId) => `/services/treatments/${treatmentId}/`,
  TREATMENT_CATEGORIES: "/services/treatment-categories/",
  TREATMENT_CATEGORY: (categoryId) =>
    `/services/treatment-categories/${categoryId}/`,
  MEMBERSHIPS: "/services/memberships/",
  MEMBERSHIP: (membershipId) => `/services/memberships/${membershipId}/`,
  MEMBERSHIP_CATEGORIES: "/services/membership-categories/",
  MEMBERSHIP_CATEGORY: (categoryId) =>
    `/services/membership-categories/${categoryId}/`,
  MEMBERSHIP_CATEGORY_UPDATE: (membershipId) =>
    `/services/memberships/${membershipId}/update_categories/`,
  MEMBERSHIP_ALLOWED_TREATMENTS: "/services/membership-allowed-treatments/",
  MEMBERSHIP_ALLOWED_TREATMENT: (treatmentId) =>
    `/services/membership-allowed-treatments/${treatmentId}/`,
};

export const LOGISTICS_API_ENDPOINTS = {
  DELIVERIES: "/logistics/delivery/",
  DELIVERY: (deliveryId) => `/logistics/delivery/${deliveryId}/`,
  DELIVERY_ITEMS: "/logistics/deliveryitems/",
  DELIVERY_ITEMS_BULK_UPDATE: `/logistics/deliveryitems/bulk_update/`,
  INVENTORIES: `/logistics/inventory/`,
  INVENTORY_BY_LOCATION: (locationId) =>
    `/logistics/inventory/?location=${locationId}`,
  INVENTORY_ITEM: (itemId) => `/logistics/inventory/${itemId}/`,
};

export const REPORT_API_ENDPOINTS = {
  REPORTS: "/reports/",
  REPORT: (productId) => `/reports/${productId}/`,
  DAILY_REPORT: `/reports/daily-sales/`,
  STORE_DAILY_REPORT: `/reports/store-sales-data/`,
  DELETE_REPORT_BY_DATE: `/reports/delete-sales-data/`,
  GET_SALES_DATA: `/reports/get-daily-sales-report/`,
  GET_PERSONAL_SALES: `/reports/get-personal-sales/`,
  GET_LOCATIONS_SALES: `/reports/get-location-sales/`,
  LEADERBOARDS: `/reports/leaderboards/`,
};

export const NOTIFICATION_API_ENDPOINTS = {
  NOTIFICATIONS: "/notifications/",
  NOTIFICATION: (notificationId) => `/notifications/${notificationId}/`,
  UPDATE_READ_STATUS: (notificationId) =>
    `/notifications/${notificationId}/update_read_status/`,
  MARK_ALL_AS_READ: "/notifications/mark_all_as_read/",
};
