import React from "react";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../context/NotificationsContext";
import {
  Badge,
  IconButton,
  CircularProgress,
  Tooltip,
  styled,
  ListItemIcon,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ErrorIcon from "@mui/icons-material/Error";

export default function NotificationsMenuItem() {
  const {
    state: { notifications },
    isLoading,
    isError,
  } = useNotifications();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/notifications");
  };

  const renderErrorIcon = () => (
    <Tooltip title="Error loading notifications">
      <ErrorIcon color="error" />
    </Tooltip>
  );

  const renderBadgeContent = () => {
    if (isLoading) return <CircularProgress size={24} color="inherit" />;
    if (isError) return renderErrorIcon();
    return (notifications || []).filter((notification) => !notification.is_read)
      .length;
  };

  const unreadCount = renderBadgeContent();

  const notificationIcon =
    unreadCount > 0 ? <NotificationsIcon /> : <NotificationsNoneIcon />;

  return (
    <ListItemIcon onClick={handleClick} sx={{ justifyContent: "center" }}>
      <Badge
        badgeContent={unreadCount}
        color="error"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ ".MuiBadge-badge": { top: 1, right: 1 } }}
      >
        {notificationIcon}
      </Badge>
    </ListItemIcon>
  );
}
