import { createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

const defaultFontFamily = [
  "Quicksan",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

const headingsFontFamily = "Poppins, " + defaultFontFamily;

const theme = createTheme({
  typography: {
    fontFamily: defaultFontFamily,
    h1: { fontFamily: headingsFontFamily },
    h2: { fontFamily: headingsFontFamily },
    h3: { fontFamily: headingsFontFamily },
    h4: { fontFamily: headingsFontFamily },
    h5: { fontFamily: headingsFontFamily },
    h6: { fontFamily: headingsFontFamily },
    button: { fontFamily: "Roboto, " + defaultFontFamily },
    // Define other specific styles as needed
  },
  palette: {
    background: {
      default: "#f7f7f7", // Setting global background color
    },
  },
});

export default theme;
