import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { logout } from "../../services/authService";
import { useNavigate, useLocation } from "react-router-dom";
import { useNotifications } from "../../context/NotificationsContext";
import {
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { mobileItems } from "./menuItems";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import { capitalize } from "../../utils/formatters";

function BottomNav() {
  const { userDetails, clearUserDetails, isAdmin } = useContext(AuthContext);
  const {
    state: { notifications },
    isLoading,
    isError,
  } = useNotifications();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event, newValue) => {
    if (newValue === "user-menu") {
      setAnchorEl(event.currentTarget);
    } else {
      navigate(newValue);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    clearUserDetails();
    handleClose();
    navigate("/login");
  };

  const handleProfileClick = () => {
    handleClose();
    navigate("/profile");
  };

  const filteredTabs = mobileItems.filter((item) => isAdmin || !item.isAdmin);

  return (
    <>
      <BottomNavigation
        value={location.pathname}
        onChange={handleChange}
        showLabels
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 999,
          "& .Mui-selected": {
            color: theme.palette.secondary.light,
            "& .MuiSvgIcon-root": {
              color: theme.palette.secondary.light,
            },
          },
          "& .MuiBottomNavigationAction-root": {
            color: theme.palette.action.active,
            maxWidth: "none",
            "&:hover": {
              backgroundColor: theme.palette.action.hover,
            },
          },
        }}
      >
        {filteredTabs.map((tab) => (
          <BottomNavigationAction
            key={tab.value}
            label={tab.label}
            value={tab.value}
            icon={tab.Icon}
          />
        ))}
        <BottomNavigationAction
          label={`${capitalize(userDetails?.firstName)} ${capitalize(
            userDetails?.lastName
          )}`}
          value="user-menu"
          icon={
            <Avatar
              alt={`${userDetails?.firstName} ${userDetails?.lastName}`}
              src={userDetails.avatar}
            />
          }
        />
      </BottomNavigation>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default BottomNav;
