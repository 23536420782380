import React, { useContext } from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { capitalize } from "../../utils/formatters";
import { adminItems, userItems } from "./menuItems";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../../services/authService";

function DesktopSidebar() {
  const { userDetails, isAdmin, clearUserDetails } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = async () => {
    await logout();
    clearUserDetails();
    navigate("/login");
  };

  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: mdUp ? 220 : 72,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          bgcolor: theme.palette.background.default,
          width: mdUp ? 220 : 72,
          boxSizing: "border-box",
          border: "none",
        },
      }}
    >
      <Box sx={{ textAlign: "center", p: 2, pb: 4 }}>
        <Avatar
          sx={{
            mx: "auto",
            height: mdUp ? 64 : 48,
            width: mdUp ? 64 : 48,
          }}
        >
          <Box
            component="img"
            sx={{ maxWidth: "100%" }}
            alt="Logo"
            src={theme?.logo}
          />
        </Avatar>
        {mdUp && (
          <Typography variant="h6" noWrap>
            {theme.companyName}
          </Typography>
        )}
      </Box>

      <List sx={{ flexGrow: 1, overflow: "auto" }}>
        {userItems.map((item, index) => (
          <Tooltip title={mdUp ? "" : item.label} placement="right" key={index}>
            <ListItemButton
              onClick={() => handleNavigation(item.value)}
              selected={isActive(item.value)}
              sx={{
                bgcolor: isActive(item.value)
                  ? theme.palette.action.selected
                  : "inherit",
                justifyContent: "center",
              }}
            >
              <ListItemIcon sx={{ justifyContent: "center" }}>
                {isActive(item.value) ? item.Icon : item.OutlineIcon}
              </ListItemIcon>
              {mdUp && <ListItemText primary={item.label} />}
            </ListItemButton>
          </Tooltip>
        ))}

        {isAdmin && (
          <>
            {mdUp && (
              <Box sx={{ mt: 5, mx: 2 }}>
                <Typography variant="subtitle1" sx={{ ml: 1 }}>
                  Admin
                </Typography>
              </Box>
            )}
            {!mdUp && <Box sx={{ mt: 5 }}></Box>}
            {adminItems.map((item, index) => (
              <Tooltip
                title={mdUp ? "" : item.title}
                placement="right"
                key={index}
              >
                <ListItemButton
                  onClick={() => handleNavigation(item.path)}
                  selected={isActive(item.path)}
                  sx={{
                    bgcolor: isActive(item.path)
                      ? theme.palette.action.selected
                      : "inherit",

                    justifyContent: "center",
                  }}
                >
                  <ListItemIcon sx={{ justifyContent: "center" }}>
                    {isActive(item.path) ? item.icon : item.OutlineIcon}
                  </ListItemIcon>
                  {mdUp && <ListItemText primary={item.title} />}
                </ListItemButton>
              </Tooltip>
            ))}
          </>
        )}
      </List>

      <Box sx={{ mt: "auto", pb: 2 }}>
        <ListItemButton
          onClick={() => navigate("/profile")}
          sx={{
            py: 2,
            justifyContent: "center",
          }}
        >
          <Avatar src={userDetails?.avatar} sx={{ mr: mdUp ? 2 : 0 }} />
          {mdUp && (
            <ListItemText
              primary={`${capitalize(userDetails?.firstName)} ${capitalize(
                userDetails?.lastName
              )}`}
              secondary={userDetails?.groups[0]}
            />
          )}
        </ListItemButton>

        <Tooltip title={mdUp ? "" : "Logout"} placement="right">
          <ListItemButton
            onClick={logout}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <LogoutIcon />
            </ListItemIcon>
            {mdUp && (
              <ListItemText primary="Logout" onClick={() => handleLogout()} />
            )}
          </ListItemButton>
        </Tooltip>
      </Box>
    </Drawer>
  );
}

export default DesktopSidebar;
