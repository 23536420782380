import dayjs from "dayjs";

export const currencyFormatter = new Intl.NumberFormat("en-PH", {
  style: "currency",
  currency: "PHP",
});

export const intCurrencyFormatter = (value) => {
  // Round the value to the nearest whole number
  const roundedValue = Math.round(value);

  // Format the number as currency without decimals
  return new Intl.NumberFormat("en-PH", {
    style: "currency",
    currency: "PHP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(roundedValue);
};

export const phpPrice = {
  type: "number",
  valueFormatter: ({ value }) => currencyFormatter.format(value),
  cellClassName: "font-tabular-nums",
};

export const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const time = dayjs().hour(hour).minute(minute).format("HH:mm");
      times.push(time);
    }
  }
  return times;
};

export function roundToNearest15Min(date) {
  const minutes = 15 * Math.round(date.minute() / 15);
  return date.clone().minute(minutes).second(0);
}

export function ordinalSuffixOf(i) {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
