// src/components/Layout.js
import React from "react";
import BottomNav from "./menu/BottomNav";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DesktopSidebar from "./menu/DesktopSidebar";

const Layout = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      {isDesktop ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "calc(100vh - 64px)",
              overflow: "hidden",
            }}
          >
            <DesktopSidebar />
            <Box
              component="main"
              sx={{ flexGrow: 1, overflow: "auto", padding: 3 }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box component="main" sx={{ paddingBottom: "56px" }}>
            {children}
          </Box>
          <BottomNav />
        </>
      )}
    </>
  );
};

export default Layout;
